import Event from './Event';
import Demand from './Demand';

export default class Topic {
  constructor(params) {
    const {
      title,
      description,
      thumbnail,
      events,
      texts,
      images
    } = params ?? {};

    this.title = title;
    this.description = description;
    this.thumbnail = thumbnail ?? {};
    this.events = events?.map((e) => {
      const item = Event.fromRes(e);
      item.demands = e.demands?.map((d) => Demand.fromRes(d)) ?? [];
      return item;
    }) ?? [];
    this.texts = texts ?? {};
    this.images = images ?? {};

    this.sponsorLogos = this.deserializeImageArray(/^sponsorLogos/);
    this.specialThanksImgs = this.deserializeImageArray(/^specialThanks/);
    this.partnersImgs = this.deserializeImageArray(/^partners/);
    this.initiatorsImgs = this.deserializeImageArray(/^initiators/);
    this.extractTexts();
  }

  get thumbnailUrl() {
    return this.thumbnail?.url;
  }

  get specialThanksHasAnyDesc() {
    return this.specialThanksImgs.some((item) => item.description);
  }

  deserializeImageArray = (regex) => {
    const items = Object.entries(this.images)?.filter(([key, obj]) => regex.test(key));
    return items.map((item) => item[1]) ?? [];
  };

  extractTexts = () => {
    this._extractSpecialThanksTexts();
    this._extractPartnersTexts();
    this._extractInitiatorsTexts();
  };

  _extractSpecialThanksTexts = () => {
    Object.entries(this.texts)?.forEach(([key, value]) => {
      const regexDesc = /specialThanksDesc-(\d+)/;
      const matchDesc = key.match(regexDesc);
      if (matchDesc) {
        const index = matchDesc[1];
        this.specialThanksImgs[index].description = value;
      }

      const regexLink = /specialThanksLink-(\d+)/;
      const matchLink = key.match(regexLink);
      if (matchLink) {
        const index = matchLink[1];
        this.specialThanksImgs[index].link = value;
      }
    });
  };

  _extractPartnersTexts = () => {
    Object.entries(this.texts)?.forEach(([key, value]) => {
      const regex = /partnersLink-(\d+)/;
      const match = key.match(regex);
      if (match) {
        const index = match[1];
        this.partnersImgs[index].link = value;
      }
    });
  };

  _extractInitiatorsTexts = () => {
    Object.entries(this.texts)?.forEach(([key, value]) => {
      const regex = /initiatorsLink-(\d+)/;
      const match = key.match(regex);
      if (match) {
        const index = match[1];
        this.initiatorsImgs[index].link = value;
      }
    });
  };


  static fromRes(data) {
    return new Topic(data);
  }
}
