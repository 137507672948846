import {
  makeObservable, computed, observable, action, runInAction, reaction
} from 'mobx';
import { t } from 'i18next';
import diff from 'deep-diff';
import dayjs from 'dayjs';
import { message } from 'antd';
import { CREATE_EVENT_STEPS, EVENT_FIELD, RESOURCE_TYPES, SIGNUP_FORM_FIELDS, KEYWORD_TYPE, EVENT_RELEASE_METHOD, CREATE_EVENT_STEPS_PROPORTION, CREATE_EVENT_PROGRESS, EVENT_REVIEW_STATUS } from 'src/constants';
import { isPro } from 'src/stores';
import ConstantsStore from 'src/stores/constants';
import { cleanseSpecialCharacters } from 'src/utils';
import IS from 'src/utils/is';
import ErrorService from 'src/services/errors';
import EventService from 'src/services/event';
import DemandService from 'src/services/demand';
import UserService from 'src/services/user';
import UploadPhotoVM from 'src/components/UploadPhoto/vm';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import GeneralModalViewModel from 'src/components/Modals/GeneralModal/vm';
import Contact from 'src/models/response/Event/Contact';
import DemandVM from './components/Step/Demand/demandVM';
import PreviewViewModel from './components/Preview/vm';

export const STEP_ORDER = {
  [CREATE_EVENT_STEPS.Basic]: 0,
  [CREATE_EVENT_STEPS.KeyFinding]: 1,
  [CREATE_EVENT_STEPS.Target]: 2,
  [CREATE_EVENT_STEPS.Vision]: 3,
  [CREATE_EVENT_STEPS.KeyItem]: 4,
  [CREATE_EVENT_STEPS.Features]: 5,
  [CREATE_EVENT_STEPS.Demand]: 6,
  [CREATE_EVENT_STEPS.Faqs]: 7,
  [CREATE_EVENT_STEPS.Registration]: 8,
  [CREATE_EVENT_STEPS.Submit]: 9
};

class CreateEventPageViewModel {

  registrationOptions = Object.values(SIGNUP_FORM_FIELDS);

  isMobile = false;
  @observable userId = null;
  @observable profile = null;

  @observable event = {};

  // temporary (before event is created)
  @observable temp = {
    name: null,
    slogan: null,
    contact: new Contact()
  };

  @observable step = CREATE_EVENT_STEPS.Basic;
  @observable isLoading = false; // for fullpage loading
  @observable showPreview = false;
  @observable sloganForDisplay = null; // only show what is saved
  @observable currentDemandTab = RESOURCE_TYPES.Service;
  @observable isFaqDone = false;
  @observable isRegistrationDone = false;

  @observable customId = null;
  @observable customIdError = null;

  @observable demands = {
    [RESOURCE_TYPES.Service]: [],
    [RESOURCE_TYPES.Funds]: [],
    [RESOURCE_TYPES.Space]: []
  };

  generalModalViewModel = new GeneralModalViewModel();
  @observable uploadBannerVM;
  @observable regionsSelectVM = new TreeSelectViewModel(ConstantsStore.regionsOptions);
  @observable previewVM;

  // for related users select
  @observable userOptions = [];
  @observable isFetching = false;

  @observable forRelease = {
    method: EVENT_RELEASE_METHOD.Auto,
    releaseAt: null,
    endAt: null
  };

  @observable isReviewTextModalOpen = false;

  @observable isEventCreated = false;
  @observable isSubmitted = false;
  @observable updatedAt = null; // timestamp

  @observable tipHeight = {};
  @observable titleAreaHeight = 80;

  @observable editingTimers = [];
  @observable sessionId;
  @observable editingPeopleCounts = {};

  @observable forGA = {
    stepsClicked: '',
    overallTimers: [],
    stepTimer: null
  };

  constructor() {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('CreateEventPage.didMount, params', props.router.params);
    console.log(props);
    const { profile, context, router } = props;
    window.scrollTo(0, 0);
    this.props = props;
    this.profile = profile;
    this.userId = profile?.userId;
    this.sessionId = context.state?.sessionId;
    this.customId = router.params?.id;
    this.isMobile = context.state?.isMobile;

    if (!this.profile.isEventCreator) {
      ErrorService.onCustomError(
        t('error_not_event_creator'),
        null,
        () => router.navigate('/')
      );
      return;
    }

    if (this.customId && IS.customId(this.customId)) {
      await Promise.all([
        this.getEventDetail(),
        this.getDemands()
      ]);

      this.initForCreatedEvent();

    } else if (this.customId) {
      // customId is invalid --> event does not exist
      this.customId = null;
      router.navigate('/create-event', { replace: true });
    }

    this.initStep();
    this.startOverallTiming(); // for GA

    localStorage.removeItem('fromCreateEventId');
  };

  willUnMount = () => {
    console.log('-create event will unmount');
    window.removeEventListener('beforeunload', this.handleWindowUnload);
    this.clearEditingTimers();
    this.updateEditing(false);
    this.disposeReactions();
    this.sendGAEvent('Click_Orders_Event_Editing');
    this.stopOverallTiming();
  };

  @action initStep = () => {
    const event = this.event ?? {};
    this.isRegistrationDone = !!event.registrationRequires && !event.registrationFields?.some((item) => item.isHalfComplete);
    this.isFaqDone = this.event[EVENT_FIELD.Faqs]?.every((item) => item.isComplete) ?? false;
    this.forGA.stepsClicked += STEP_ORDER[CREATE_EVENT_STEPS.Basic];

    // find the step that is not done yet
    // const theStep = Object.entries(this.isDone).find((item) => !item[1]);
    // if (theStep) {
    //   this.onSwitchStep(theStep[0]);
    // } else {
    //   this.onSwitchStep(CREATE_EVENT_STEPS.Basic);
    // }
  };

  @action setTipHeight = (key, value) => {
    this.tipHeight[key] = value;
  };

  @action initForCreatedEvent = () => {
    this.isEventCreated = true;
    this.getEmptyDataReadyForEditing();
    this.uploadBannerVM = new UploadPhotoVM(
      {
        maxCount: 1,
        items: [],
        type: 'event'
      }
    );

    this.previewVM = new PreviewViewModel({ step: this.step, id: this.customId });

    this.startToUpdateEditing();
    this.startToCheckEditing();

    window.addEventListener('beforeunload', this.handleWindowUnload);
  };

  getEmptyDataReadyForEditing = () => {
    this.event.initEmptyItemInArray();

    Object.entries(this.demands).forEach((item) => {
      const list = item[1];
      const type = item[0];
      if (list?.length === 0) {
        this.demands[type] = [
          new DemandVM({
            type,
            eventId: this.customId
          })
        ];
      }
    });
  };

  getEventDetail = async () => {
    try {
      const res = await EventService.detail(this.customId);
      this.deserialize(res);
      console.log('Event', this.event);
      runInAction(() => {
        if (this.event.slogan) {
          this.sloganForDisplay = this.event.slogan;
        }
      });
    } catch (error) {
      const { router } = this.props;
      console.log(error);
      switch (error?.response?.status) {
        case 400: // id is invalid
          router.navigate('/create-event', { replace: true });
          runInAction(() => {
            this.customId = null;
          });
          break;
        case 404:
          ErrorService.onCustomError(
            t('error_create_event_detail_404'),
            null,
            this.redirectBack
          );
          break;
        case 403:
          ErrorService.onCustomError(
            t('error_create_event_detail_403'),
            null,
            () => this.toOverview()
          );
          break;
        default:
      }
    }
  };

  getDemands = async () => {
    try {
      Object.keys(this.demands).forEach(async (type) => {
        const res = await DemandService.getList(type, this.customId);
        this.initDemandList(res, type);
      });

    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  // /////////////////////////////////////////////////////////////////////

  @action onCreate = async () => {
    console.log('..on create', this.customId);

    try {
      const res = await EventService.create(this.customId);
      const { router } = this.props;
      router.navigate(`/create-event/${this.customId}`, { replace: true });
      await this.getEventDetail();
      await this.updateTempData();
      this.initForCreatedEvent();
      message.success(t('save_success'));
    } catch (error) {
      console.log(error);
      if (error.response?.status === 409) {
        runInAction(() => {
          this.customIdError = t('error_customId_exist');
          console.log('conflict', this.customIdError);
        });
      } else {
        ErrorService.onDefaultError(error);
      }
    }
  };

  onUpdate = async (data, shouldShowMessage, isRecalled = false) => {
    console.log('..on update', this.customId, data);
    try {
      const res = await EventService.update(this.customId, data);
      console.log('####### updated', dayjs(res.updatedAt).format('MM/DD HH:mm'), res);
      this.setUpdatedAt(dayjs(res.updatedAt).valueOf());

      if (shouldShowMessage) {
        message.success(t('save_success'));
      }

      if (this.showPreview) {
        this.previewVM.resetIframe();
      }

      return res;

    } catch (error) {
      console.log(error);
      if (error.response?.status === 409 && !isRecalled) {
        this.onUpdate(data, shouldShowMessage, true);
      } else {
        ErrorService.onDefaultError(error);
      }

      return null;
    }
  };

  @action setUpdatedAt = (timestamp = dayjs().valueOf()) => {
    this.updatedAt = timestamp;
  };

  @action updateTempData = async () => {
    const dataToUpdate = {};

    if (this.temp.name) {
      this.event.onChange(EVENT_FIELD.Name, this.temp.name);
      dataToUpdate.name = this.temp.name;
    }

    if (this.temp.slogan) {
      this.event.onChange(EVENT_FIELD.Slogan, this.temp.slogan);
      runInAction(() => {
        this.sloganForDisplay = this.temp.slogan;
      });
      dataToUpdate.slogan = this.temp.slogan;
    }

    const contact = this.temp.contact;
    if (!contact.isEmpty) {
      this.event.onChange(EVENT_FIELD.Contact, contact.name, 'name');
      this.event.onChange(EVENT_FIELD.Contact, contact.jobTitle, 'jobTitle');
      this.event.onChange(EVENT_FIELD.Contact, contact.phone, 'phone');
      this.event.onChange(EVENT_FIELD.Contact, contact.email, 'email');
      if (contact.isComplete) {
        dataToUpdate.contact = contact.serialize();
      }
    }

    await this.onUpdate(dataToUpdate);
  };

  onSave = async (fieldName, shouldShowMessage = true) => {
    console.log('..on save', fieldName);
    if (!this.customId) {
      return;
    }

    if (this.cannotSave[fieldName]) {
      return;
    }

    const data = this.serialize(fieldName);
    const res = await this.onUpdate(data, shouldShowMessage);

    if (res) {
      // data syncing with the event model
      if (fieldName === EVENT_FIELD.Slogan) {
        runInAction(() => {
          this.sloganForDisplay = this.event?.slogan;
        });
      }

      if ([EVENT_FIELD.CoInitiators, EVENT_FIELD.Collaborators, EVENT_FIELD.CoOrganiser].includes(fieldName)) {
        this.deserializeRelatedUsers(res);
      }

      if (fieldName === EVENT_FIELD.Banner) {
        this.event.onChange(EVENT_FIELD.Banner, res.banner);
      }
    }
  };

  onCustomIdBlur = async () => {
    if (!IS.customId(this.customId)) {
      if (this.customId?.length >= 30) {
        this.customIdError = t('error_customId_no_longer_than_30');
      } else {
        this.customIdError = t('error_customId_not_valid');
      }
      return;
    }

    if (this.isEventCreated) {
      if (this.event.id !== this.customId) {
        // change event customId
        console.log('new id', this.event.id, this.customId);

        try {
          const res = await EventService.update(this.event.id, { customId: this.customId });
          console.log(res);
          runInAction(() => {
            this.event.id = this.customId;
          });
          const { router } = this.props;
          router.navigate(`/create-event/${this.customId}`, { replace: true });
          message.success(t('save_success'));

          this.previewVM.resetId(this.customId);
          Object.values(this.demands).forEach((demandsArr) => {
            demandsArr.forEach((d) => d.resetEventId(this.customId));
          });

        } catch (error) {
          console.log(error);
          if (error.response?.status === 409) {
            runInAction(() => {
              this.customIdError = t('error_customId_exist');
              console.log('conflict', this.customIdError);
            });
          } else {
            ErrorService.onDefaultError(error);
          }
        }
      }
    } else {
      this.onCreate();
    }
  };

  @action onSubmit = async () => {
    try {
      this.isLoading = true;
      const data = this.serializeDataForRelease();
      // NOTE: enable recruit first so the event starts recruiting as soon as it is released
      await this.onUpdate({ enableRecruit: true });

      const res = await EventService.submit(this.customId, data);
      console.log('submit', res);
      runInAction(() => {
        this.isSubmitted = true;
        window.scrollTo(0, 0);
      });

      this.sendGAEvent('Add_Reqirement_to_SDG');

    } catch (error) {
      console.log(error);
      if (error?.response?.data?.code === '0xd00c') {
        this.generalModalViewModel.open({
          title: t('modal_quota_insufficient_title'),
          content: isPro ? t('modal_quota_insufficient_content_2') : t('modal_quota_insufficient_content'),
          onConfirm: isPro ? null : this.toProduct,
          showCancelButton: !isPro,
          buttonText: isPro ? t('ok') : t('modal_quota_insufficient_confirm_button'),
          illustrationType: 1
        });
      } else if (error?.response?.status === 406) {
        ErrorService.onCustomError(t('error_create_event_submit_406'));
      } else {
        ErrorService.onDefaultError(error);
      }
    } finally {
      this.toggleReviewTextModal();
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  // /////////////////////////////////////////////////////////////////////

  @action onSwitchStep = (label) => {
    this.step = label;
    this.previewVM.switchStep(label);
    window.scrollTo(0, 0);

    const order = STEP_ORDER[label].toString();
    if (this.forGA.stepsClicked.slice(-1) !== order) {
      this.forGA.stepsClicked += order;
    }
  };

  onClickNextStep = () => {
    const currentOrder = STEP_ORDER[this.step];
    const nextStep = Object.entries(STEP_ORDER).find((item) => item[1] === currentOrder + 1);
    if (nextStep) {
      this.onSwitchStep(nextStep[0]);
    }
  };

  // on change data ///////////////////////////////////////////////////////

  @action onChange = (fieldName, value, key) => {
    console.log('before change', fieldName, value, key, this.event);

    if (this.event.onChange) {
      this.event.onChange(fieldName, value, key);
    } else if (key) {
      this.event[fieldName][key] = value;
    } else {
      this.event[fieldName] = value;
    }
  };

  onChangeBasic = (fieldName, value, key) => {
    if (this.isEventCreated && this.event.onChange) {
      this.event.onChange(fieldName, value, key);
    } else if (key) {
      this.temp[fieldName][key] = value;
    } else {
      this.temp[fieldName] = value;
    }
  };

  @action onChangeCustomId = (value) => {
    if (this.customIdError) {
      this.customIdError = null;
    }
    this.customId = value;
  };

  @action onUploadPhoto = async (item, fieldName) => { // to upload immediately
    const UploadVM = item.uploadPhotoVM;
    this.isLoading = true;
    try {
      const res = await UploadVM.onPhotosUpload(this.customId);
      console.log('.......uploaded', res);
      if (res) {
        item.updatePhotos(UploadVM.postList);
        await this.onSave(fieldName);
      }
    } catch (error) {
      console.log(error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action onUploadBanner = async () => { // to upload immediately
    const UploadVM = this.uploadBannerVM;
    this.isLoading = true;
    try {
      await UploadVM.onPhotosUpload(this.customId);
      await this.onSave(EVENT_FIELD.Banner);
    } catch (error) {
      console.log(error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action onRemoveBanner = () => {
    // 只是 UI 暫時不顯示，此處並沒有更新 event 資料
    this.event.onChange(EVENT_FIELD.Banner, null);
    if (this.uploadBannerVM) {
      this.uploadBannerVM.onRemoveFile(this.uploadBannerVM.fileList[0]);
    }
  };

  handleChangeSdg = (value) => {
    this.event.onChangeSdg(value);
    this.event.vision?.onChangeSdgsArray(this.event.sdgs);
    this.onSave(EVENT_FIELD.Sdgs, this.step === CREATE_EVENT_STEPS.KeyFinding);
    if (this.event.vision.isComplete) {
      this.onSave(EVENT_FIELD.Vision, this.step === CREATE_EVENT_STEPS.Vision);
    }
  };

  @action switchDemandType = (tab) => {
    this.currentDemandTab = tab;
  };

  @action onAddDemand = () => {
    this.demands[this.currentDemandTab].push(new DemandVM({
      type: this.currentDemandTab,
      eventId: this.customId
    }));
  };

  @action onDeleteDemand = (index) => {
    this.demands[this.currentDemandTab] = this.demands[this.currentDemandTab].filter((item, i) => i !== index);
  };

  onRegistrationRequiresChange = (isChecked, opt) => {
    this.event.onChangeRegistrationRequires(opt, isChecked);
    this.onSave('registrationRequires');
  };

  @action onChangeKeyword = (key, value) => {
    this.event.onChange('keywordsObj', value, key);
  };

  @action onChangeRelatedUsers = (key, value, option) => {
    this[key] = option.map((opt, i) => {
      if (opt.value) {
        return opt;
      }

      return { key: value[i], value: value[i], label: value[i] };
    });
    this.onSave(key);
  };

  @action onChangeForRelease = (key, value) => {
    if (key === 'releaseAt') {
      const release = value ? dayjs(value).startOf('day') : null;
      this.forRelease[key] = release;
    } else if (key === 'endAt') {
      const end = value ? dayjs(value).endOf('day') : null;
      this.forRelease[key] = end;
    } else {
      this.forRelease[key] = value;
    }
  };

  // ///////////////////////////////////////////////////////////////////

  @action togglePreview = () => {
    this.showPreview = !this.showPreview;
    if (this.showPreview) {
      this.sendGAEvent('Enable_Event_Editing_Preview');
    }
  };

  @action toggleReviewTextModal = async () => {
    if (!this.isReviewTextModalOpen) {
      if (!this.isSubmittable) {
        return;
      }
    }

    this.isReviewTextModalOpen = !this.isReviewTextModalOpen;
  };

  @action onUserSearch = async (e) => {
    console.log('searching', e);
    if (e.length > 1) {
      this.isFetching = true;
      await this.getUserOptions(e);
      runInAction(() => {
        this.isFetching = false;
      });
    }
  };

  @action getUserOptions = async (keyword) => {
    try {
      const res = await UserService.suggest({ keyword });
      console.log('user res', res);
      if (res?.length > 0) {
        const list = res.map((user) => ({
          label: user.displayName,
          value: user.id,
          key: user.id
        }));

        runInAction(() => {
          this.userOptions = list;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action resetUserOptions = () => {
    this.userOptions = [];
  };

  faqDidmount = () => {
    this.faqDisposer = reaction(
      () => this.event[EVENT_FIELD.Faqs].every((f) => f.isComplete),
      () => {
        this.setFaqStatus();
      }
    );
  };

  @action faqUnmount = () => {
    this.removeEmptyItemsWhenUnmount(EVENT_FIELD.Faqs);
    this.setFaqStatus();
    if (this.faqDisposer) {
      this.faqDisposer();
    }
  };

  @action setFaqStatus = () => {
    this.isFaqDone = !this.event[EVENT_FIELD.Faqs]?.some((item) => item.isHalfComplete);
  };

  registrationDidmount = () => {
    this.registrationDisposer = reaction(
      () => this.event[EVENT_FIELD.RegistrationFields].every((f) => f.isComplete),
      () => {
        this.setRegistrationStatus();
      }
    );
  };

  @action registrationUnmount = () => {
    this.removeEmptyItemsWhenUnmount(EVENT_FIELD.RegistrationFields, 0);
    const regFields = this.event[EVENT_FIELD.RegistrationFields] ?? [];
    regFields.forEach(async (f) => {
      if (f.isEditing && f.isComplete) {
        f.toggleEdit();
        await this.onSave(EVENT_FIELD.RegistrationFields);
      }
    });
    this.setRegistrationStatus();
    if (this.registrationDisposer) {
      this.registrationDisposer();
    }
  };

  @action setRegistrationStatus = () => {
    const regFields = this.event[EVENT_FIELD.RegistrationFields] ?? [];
    this.isRegistrationDone = (regFields.length === 0 || !regFields.some((item) => item.isHalfComplete));
  };

  @action initDemandList = (data, type) => {
    if (data.length !== 0) {
      this.demands[type] = data.map((item) => DemandVM.fromRes(type, this.customId, item));
    } else {
      this.demands[type] = [
        new DemandVM({
          type,
          eventId: this.customId
        })
      ];
    }
  };

  onDemandStepUnmount = () => {
    this.removeEmptyDemandVMUntilOne();
    [RESOURCE_TYPES.Service, RESOURCE_TYPES.Funds, RESOURCE_TYPES.Space].forEach((key) => {
      this.demands[key].forEach((d) => d.filterEmptyStringInArrs());
    });
  };

  @action removeEmptyDemandVMUntilOne = () => {
    [RESOURCE_TYPES.Service, RESOURCE_TYPES.Funds, RESOURCE_TYPES.Space].forEach((key) => {
      const items = this.demands[key] ?? [];
      console.log(items);
      this.demands[key] = items?.every((item) => item.isEmpty)
        ? items.slice(0, 1)
        : items.filter((item, i) => !item.isEmpty);
    });
  };

  removeEmptyItemsWhenUnmount = (fieldName, until = 1) => {
    if (this.event?.removeEmptyItems) {
      this.event.removeEmptyItems(fieldName, until);
    }
  };

  initRegionsTreeSelect = () => {
    this.regionsSelectVM.initCheckState(this.event.regions ?? []);
    this.regionsSelectDisposer = reaction(
      () => this.regionsSelectVM.selectedItems,
      () => {
        this.onRegionsTreeSelectChange(this.regionsSelectVM.selectedItems);
      }
    );
  };

  onRegionsTreeSelectChange = (selectedItems) => {
    const values = selectedItems.map((item) => item.idValue);
    const isChanged = diff(values, this.event.regions);
    if (isChanged) {
      this.event.onChange(EVENT_FIELD.Regions, values);
      this.onSave(EVENT_FIELD.Regions);
    }
  };

  disposeReactions = () => {
    if (this.regionsSelectDisposer) {
      this.regionsSelectDisposer();
    }
    Object.values(this.demands)?.forEach((demandVM) => {
      demandVM.forEach((d) => d.dispose());
    });
  };

  // event editing status /////////////////////////////

  @action startToUpdateEditing = async () => {
    await this.updateEditing(true);
    const timerId = setInterval(() => this.updateEditing(true), 30000);
    runInAction(() => {
      this.editingTimers.push(timerId);
    });
  };

  @action startToCheckEditing = async () => {
    await this.getEditing();
    const timerId = setInterval(this.getEditing, 30000);
    runInAction(() => {
      this.editingTimers.push(timerId);
    });
  };

  @action clearEditingTimers = () => {
    this.editingTimers.forEach((timerId) => clearInterval(timerId));
    this.editingTimers = [];
  };

  updateEditing = async (isEditing) => {
    try {
      const res = await EventService.updateEditing(
        this.customId,
        {
          id: this.sessionId,
          step: this.step,
          isEditing
        }
      );
      console.log('put editing', res);
    } catch (error) {
      console.log(error);
    }
  };

  getEditing = async () => {
    try {
      const res = await EventService.getEditing(
        this.customId,
        { id: this.sessionId }
      );
      console.log('get editing', res);
      runInAction(() => {
        this.editingPeopleCounts = res.isEditing ?? {};
      });
    } catch (error) {
      console.log(error);
      switch (error?.response?.status) {
        case 403:
          ErrorService.onCustomError(
            t('error_create_event_detail_403'),
            null,
            () => this.toOverview()
          );
          break;
        default:
      }
    }
  };

  handleWindowUnload = (ev) => {
    ev.preventDefault();
    console.log('!!!!!!!!!!!!!!!!! before unload');
    this.updateEditing(false);
    this.clearEditingTimers();
    // eslint-disable-next-line no-param-reassign
    // ev.returnValue = '';
  };

  // ///////////////////////////////////////

  @computed get isDone() {
    const event = this.event ?? {};

    return {
      [CREATE_EVENT_STEPS.Basic]:
        (!!this.customId
          && !!event.name
          && !!event.slogan
          && event[EVENT_FIELD.Contact].isComplete
        ),
      [CREATE_EVENT_STEPS.KeyFinding]:
        (event.sdgs?.length !== 0
          && !!event.keyFinding?.title
          && !!event.keyFinding?.description
          && (!!event.keyFinding?.photos[0] || !!event.keyFinding?.videos[0])),
      [CREATE_EVENT_STEPS.Target]: event.targets?.every((ta) => ta.isComplete),
      [CREATE_EVENT_STEPS.Vision]: event.vision?.isComplete,
      [CREATE_EVENT_STEPS.KeyItem]: event.keyItem?.isComplete,
      [CREATE_EVENT_STEPS.Features]: event.features?.every((f) => f.isComplete),
      [CREATE_EVENT_STEPS.Demand]: this.isDemandDone,
      [CREATE_EVENT_STEPS.Faqs]: this.isFaqDone,
      [CREATE_EVENT_STEPS.Registration]: this.isRegistrationDone,
      [CREATE_EVENT_STEPS.Submit]: (
        !!event.description
        && (!!event.banner || this.uploadBannerVM?.postList?.length !== 0)
        && !Object.values(this.event?.keywordsObj).some((v) => !v)
        && this[EVENT_FIELD.CoInitiators]?.length !== 0
        && this.regionsSelectVM.selectedItems?.length !== 0
        && !!event.privacy
        && this.isReleaseMethodDone
      )
    };
  }

  @computed get isReleaseMethodDone() {
    if (this.forRelease.method === EVENT_RELEASE_METHOD.Manual) {
      return true;
    }
    if (this.forRelease.method === EVENT_RELEASE_METHOD.Auto) {
      return !!this.forRelease.endAt;
    }
    if (this.forRelease.method === EVENT_RELEASE_METHOD.Scheduled) {
      return !!this.forRelease.releaseAt && !!this.forRelease.endAt;
    }
    return false;
  }

  @computed get progress() {
    const sum = Object.entries(this.isDone).reduce((accumulator, currentItem) => {
      const step = currentItem[0];
      const proportion = CREATE_EVENT_STEPS_PROPORTION[step];
      if (currentItem[1]) {
        return accumulator + proportion;
      }
      return accumulator;
    }, 0);

    return sum;
  }

  @computed get progressPhase() { // 0, 1, 2, 3, 4, 5
    const [level, threshold] = Object.entries(CREATE_EVENT_PROGRESS)
      .reverse()
      .find(([lv, thresh]) => this.progress >= thresh) || [0];
    return parseInt(level, 10);
  }

  @computed get isDemandDone() {
    const arr = Object.entries(this.demands)
      .find((item) => item[1]?.length !== 0
        && item[1].some((demand) => demand.isComplete));
    return !!arr;
  }

  @computed get cannotSave() {
    const event = this.event;
    if (!event) {
      return true;
    }

    return {
      [EVENT_FIELD.Name]: this.isEventCreated ? !event.name : !this.temp.name,
      [EVENT_FIELD.Slogan]: this.isEventCreated ? !event.slogan : !this.temp.slogan,
      [EVENT_FIELD.Contact]: this.isEventCreated ? !event.contact?.isComplete : !this.temp.contact?.isComplete,
      [EVENT_FIELD.KeyFinding]: (!event.keyFinding?.title || !event.keyFinding?.description),
      [EVENT_FIELD.Targets]: event.targets?.some((ta) => !ta.option),
      [EVENT_FIELD.Vision]: !event.vision?.isComplete,
      [EVENT_FIELD.KeyItem]: !event.keyItem?.isComplete,
      [EVENT_FIELD.Features]: event.features?.some((f) => f.isHalfComplete),
      [EVENT_FIELD.Faqs]: event.faqs?.some((f) => f.isHalfComplete),
      [EVENT_FIELD.Keywords]: !this.isCustomKeywordValid,
      [EVENT_FIELD.Banner]: this.uploadBannerVM?.postList?.length === 0
    };
  }

  @computed get services() {
    return this.demands[RESOURCE_TYPES.Service];
  }

  @computed get funds() {
    return this.demands[RESOURCE_TYPES.Funds];
  }

  @computed get spaces() {
    return this.demands[RESOURCE_TYPES.Space];
  }

  @computed get anyDemandId() {
    if (this.services.length !== 0 && this.services[0].id) {
      return this.services[0].id;
    }

    if (this.funds.length !== 0 && this.funds[0].id) {
      return this.funds[0].id;
    }

    if (this.spaces.length !== 0 && this.spaces[0].id) {
      return this.spaces[0].id;
    }

    return null;
  }

  @computed get keywordOptionSdg() {
    return this.event?.sdgs?.map((sdg) => {
      const label = ConstantsStore.flattenedSdgsOptions.find((el) => el.value === sdg)?.label;
      const labelForKeyword = cleanseSpecialCharacters(label);
      return {
        label: labelForKeyword,
        value: `${labelForKeyword}_${sdg}`
      };
    })?.filter((opt) => !!opt.value);
  }

  @computed get keywordOptionTarget() {
    return this.event?.targets?.map((ta) => {
      const label = cleanseSpecialCharacters(ta.optionName);
      return {
        label,
        value: label
      };
    })?.filter((opt) => !!opt.value);
  }

  @computed get keywordOptionKeyItem() {
    return this.event?.keyItem?.items?.map((k) => {
      const label = cleanseSpecialCharacters(k.optionName);

      return {
        label,
        value: label
      };
    })?.filter((opt) => !!opt.value);
  }

  @computed get isSubmitStep() {
    return this.step === CREATE_EVENT_STEPS.Submit;
  }

  @computed get isSubmittable() {
    return this.event?.canSubmit && !Object.values(this.isDone).some((v) => !v);
  }

  @computed get submitButtonText() {
    if (this.event?.canSubmit || !this.isEventCreated) {
      return t('confirm_and_submit');
    }

    if (this.event.review) {
      if (this.event.review.status === EVENT_REVIEW_STATUS.Accepted) {
        return t('finish_editting');
      }

      return t(`review_${this.event.review.status}`);
    }

    return null;
  }

  @computed get isSubmitButtonDisable() {
    if (this.event?.review?.status === EVENT_REVIEW_STATUS.Accepted) {
      return false;
    }

    return !this.isSubmittable;
  }

  @computed get onClickSubmitButton() {
    if (this.event?.review?.status === EVENT_REVIEW_STATUS.Accepted) {
      return this.redirectBack;
    }

    return () => {
      const errorMessage = this.checkDateForReview();
      if (errorMessage) {
        ErrorService.onCustomError(errorMessage);
      } else {
        this.toggleReviewTextModal();
      }
    };
  }

  checkDateForReview = () => {
    if (this.forRelease.method === EVENT_RELEASE_METHOD.Manual) {
      return null;
    }

    if (!this.forRelease.endAt) {
      return t('error_event_end_date_is_empty');
    }

    if (this.forRelease.endAt.isBefore(dayjs().startOf('day'))) {
      return t('error_event_end_date_is_before_today');
    }

    if (this.forRelease.method === EVENT_RELEASE_METHOD.Scheduled) {
      if (!this.forRelease.releaseAt) {
        return t('error_event_start_date_is_empty');
      }

      if (this.forRelease.releaseAt.isBefore(dayjs().startOf('day'))) {
        return t('error_event_start_date_is_before_today');
      }

      if (this.forRelease.endAt.isBefore(this.forRelease.releaseAt)) {
        return t('error_event_end_date_is_before_start_date');
      }
    }

    return null;
  };

  @computed get name() {
    if (this.isEventCreated) {
      return this.event.name;
    }
    return this.temp.name;
  }

  @computed get slogan() {
    if (this.isEventCreated) {
      return this.event.slogan;
    }
    return this.temp.slogan;
  }

  @computed get contact() {
    if (this.isEventCreated) {
      return this.event.contact;
    }
    return this.temp.contact;
  }

  @computed get releaseMethodUIString() {
    switch (this.forRelease.method) {
      case EVENT_RELEASE_METHOD.Auto:
        return `${t('event_release_method_result_auto')} ${dayjs(this.forRelease.endAt).format('YYYY/MM/DD')}`;
      case EVENT_RELEASE_METHOD.Manual:
        return `${t('event_release_method_result_manual')}`;
      case EVENT_RELEASE_METHOD.Scheduled:
        return `${t('event_release_method_result_scheduled')} ${dayjs(this.forRelease.releaseAt).format('YYYY/MM/DD')} - ${dayjs(this.forRelease.endAt).format('YYYY/MM/DD')}`;
      default:
    }

    return '';
  }

  @computed get isCustomKeywordValid() {
    return IS.keyword(this.event?.keywordsObj[KEYWORD_TYPE.Custom]);
  }

  @computed get previewUrl() {
    if (!this.customId) {
      return null;
    }

    return this.previewVM?.path;
  }

  // //////////////////////////

  @action deserialize = (data) => {
    this.event = data;
    this.sloganForDisplay = data.slogan;
    this.deserializeRelatedUsers(data);
    this.initRegionsTreeSelect();
    if (this.event.contact?.isEmpty) {
      this.event.onChange(EVENT_FIELD.Contact, this.profile?.name, 'name');
      this.event.onChange(EVENT_FIELD.Contact, this.profile?.email, 'email');
      this.event.onChange(EVENT_FIELD.Contact, this.profile?.phone, 'phone');
    }
    if (this.event.vision.sdgs?.length === 0 && this.event.sdgs?.length !== 0) {
      this.event.vision.onChangeSdgsArray(this.event.sdgs);
    }
    this.deserializeForRelease();

    this.updatedAt = data.updatedAt;
  };

  @action deserializeForRelease = () => {
    if (!this.forRelease.releaseAt && this.event.releaseAt) {
      this.forRelease.releaseAt = dayjs(this.event.releaseAt);
    }

    if (!this.forRelease.endAt && this.event.endAt) {
      this.forRelease.endAt = dayjs(this.event.endAt);
    }

    if (this.forRelease.releaseAt && this.forRelease.endAt) {
      this.forRelease.method = EVENT_RELEASE_METHOD.Scheduled;
    }
  };

  @action deserializeRelatedUsers = (event) => {
    [EVENT_FIELD.CoInitiators, EVENT_FIELD.Collaborators, EVENT_FIELD.CoOrganiser].forEach((key) => {
      this[key] = event.relatedUsers[key]?.map((user) => ({ label: user.nameForDisplay, value: user.value, key: user.value })) ?? [];
    });
  };

  serialize = (fieldName) => {
    const event = this.event ?? {};

    switch (fieldName) {
      case EVENT_FIELD.KeyFinding:
        return {
          [fieldName]: event.keyFinding?.serialize()
        };
      case EVENT_FIELD.Targets:
        return {
          [fieldName]: event.targets?.map((item) => item.serialize())
        };
      case EVENT_FIELD.KeyItem:
        return {
          [fieldName]: event.keyItem?.serialize()
        };
      case EVENT_FIELD.Features:
        return {
          [fieldName]: event.features
            .filter((item) => item.isComplete)?.map((item) => item.serialize())
        };
      case EVENT_FIELD.RegistrationFields:
        return {
          [fieldName]: event.registrationFields
            .filter((item) => item.isComplete)?.map((item) => item.serialize())
        };
      case EVENT_FIELD.Banner:
        return {
          [fieldName]: this.uploadBannerVM?.postList[0]
        };
      case EVENT_FIELD.Keywords:
        return {
          [fieldName]: event.keywords
        };
      case EVENT_FIELD.CoInitiators:
      case EVENT_FIELD.Collaborators:
      case EVENT_FIELD.CoOrganiser:
        return {
          [fieldName]: this[fieldName]?.map((item) => item.value)
        };
      case EVENT_FIELD.Faqs:
        return {
          [fieldName]: event[EVENT_FIELD.Faqs]
            .filter((item) => item.isComplete)?.map((item) => item.serialize())
        };
      case EVENT_FIELD.Regions:
        return {
          [fieldName]: this.regionsSelectVM.selectedItems?.map((item) => item.idValue) ?? []
        };
      default:
        return {
          [fieldName]: event[fieldName]
        };
    }
  };

  // 三種上架流程
  // 1. 審核通過，立即上架 (isAutoActive = true, releaseAt = null, endAt != null)
  //  *    startAt (招募開始) = 審核通過時間
  //  *    endAt (招募結束) = req.body.endAt
  //  *
  // 2. 排程自動上架 (isAutoActive = true, releaseAt != null, endAt != null)
  //  *    startAt (招募開始) = req.body.releaseAt
  //  *    endAt (招募結束) = req.body.endAt
  //  *
  // 3. 手動上架 (isAutoActive = false, releaseAt = null, endAt = null)
  //  *    startAt (招募開始) = 手動上架時間
  //  *    endAt (招募結束) = 上架 API req.body.endAt

  serializeDataForRelease = () => {
    const {
      method,
      releaseAt,
      endAt
    } = this.forRelease;

    switch (method) {
      case EVENT_RELEASE_METHOD.Auto:
        return {
          isAutoActive: true,
          releaseAt: null,
          endAt: endAt.toISOString()
        };
      case EVENT_RELEASE_METHOD.Scheduled:
        return {
          isAutoActive: true,
          releaseAt: releaseAt.toISOString(),
          endAt: endAt.toISOString()
        };
      case EVENT_RELEASE_METHOD.Manual:
        return {
          isAutoActive: false,
          releaseAt: null,
          endAt: null
        };
      default:
        return {};
    }
  };

  // /////////////////////////
  toProduct = () => {
    const { navigate } = this.props.router;
    navigate('/product');
    localStorage.setItem('fromCreateEventId', this.customId); // for navigating back here after checking out
  };

  toOverview = () => {
    const { navigate } = this.props.router;
    navigate('/create-event-overview');
  };

  toEventHosted = () => {
    const { navigate } = this.props.router;
    navigate('/user/event-hosted');
  };

  redirectBack = () => {
    const { navigate, location } = this.props.router;
    const source = location?.state?.source;
    if (source) {
      navigate(source);
    } else {
      this.toEventHosted();
    }
  };

  // Google Analytics //////////////////////////////////////////
  startOverallTiming = () => {
    const timerId45 = setTimeout(() => this.sendGAEvent('Event_Editing_Over_45Min'), 2700000); // 45 mins
    const timerId90 = setTimeout(() => this.sendGAEvent('Event_Editing_Over_90Min'), 5400000); // 90 mins
    const timerId120 = setTimeout(() => this.sendGAEvent('Event_Editing_Over_120Min'), 7200000); // 120 mins
    const timerId180 = setTimeout(() => this.sendGAEvent('Event_Editing_Over_180Min'), 10800000); // 180 mins
    runInAction(() => {
      this.forGA.overallTimers = [timerId45, timerId90, timerId120, timerId180];
    });
  };

  @action stopOverallTiming = () => {
    this.forGA.overallTimers.forEach((timerId) => clearTimeout(timerId));
    this.forGA.overallTimers = [];
  };

  startStepTiming = () => {
    const timerId = setTimeout(() => this.sendGAEvent(`Event_Editing_Over_30Min_Step${STEP_ORDER[this.step]}`), 1800000); // 30 mins
    runInAction(() => {
      this.forGA.stepTimer = timerId;
    });
  };

  stopStepTiming = () => {
    clearTimeout(this.forGA.stepTimer);
  };

  sendGAEvent = (eventName) => {
    if (eventName === 'Add_Reqirement_to_SDG') {
      Object.entries(this.demands)?.forEach((item) => {
        const type = item[0];
        const list = item[1];
        list.forEach((demandVM) => {
          window.gtag(
            'event',
            eventName,
            {
              SDG: this.event.sdgs,
              Requrement_Type: type,
              Requirement_Amount: demandVM.demandAmount
            }
          );
        });
      });

      // console.log('[GA event sent!]', eventName);
      return;
    }

    let params = {};
    switch (eventName) {
      case 'Enable_Event_Editing_Preview':
      case 'Open_Event_Editing_Preview_Blank':
      case `Event_Editing_Over_30Min_Step${STEP_ORDER[this.step]}`:
      case 'Event_Editing_Over_45Min':
      case 'Event_Editing_Over_90Min':
      case 'Event_Editing_Over_120Min':
      case 'Event_Editing_Over_180Min':
        params = {
          Event_ID: this.customId
        };
        break;
      case 'Click_Orders_Event_Editing':
        params = {
          Event_ID: this.customId,
          Orders: `'${this.forGA.stepsClicked ? this.forGA.stepsClicked : '0'}` // add ' at the begining to prevent Google from automatically casting numeric value to a number type
        };
        break;
      default:
    }

    window.gtag('event', eventName, params);
    // console.log('[GA event sent!]', eventName);
  };

}

export default CreateEventPageViewModel;
