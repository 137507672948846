import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { CREATE_EVENT_STEPS, EVENT_ELEMENT_ID } from 'src/constants';
import busyCursor from 'src/stores/busyCursor';
import pretendTo from 'src/stores/pretendTo';

class PreviewViewModel {
  @observable step = CREATE_EVENT_STEPS.Basic;
  @observable id = null;
  @observable targetId = null;
  @observable timerId = null;
  @observable isReady = false;
  @observable iframe = null;
  @observable innerDoc = null;

  @observable key = 0;

  // for preview of sign up page
  @observable demandId = null;

  constructor(props) {
    makeObservable(this);

    runInAction(() => {
      this.step = props.step;
      this.id = props.id;
    });
  }

  @action didMount = (props) => {
    this.demandId = props.demandId;
  };

  getHost = () => {
    // local
    if (/:\d{4,}$/.test(window.location.origin)) {
      const r = window.location.origin.split(/:\d{4,}/);
      if (r[0]) {
        return `${r[0]}:3000`;
      }
    }

    // production
    return 'https://goaction.5percent-design-action.com';
  };

  @computed get path() {
    if (!this.id) {
      return null;
    }

    let path;

    if (this.step === CREATE_EVENT_STEPS.Registration) {
      if (this.demandId) {
        path = `/sign-up-for-event/${this.id}/${this.demandId}`;
      } else {
        path = `/sign-up-for-event/${this.id}`;
      }

    } else {
      path = `/events/${this.id}`;
    }

    return `${path}${pretendTo.id ? `?pretendTo=${pretendTo.id}` : ''}`;

  }

  @computed get src() {
    if (!this.path) {
      return null;
    }

    return `${this.getHost()}${this.path}`;
  }

  @action switchStep = (step) => {
    this.isReady = false;
    this.step = step;
  };

  @action resetIframe = () => {
    this.isReady = false;
    this.key += 1;
  };

  @action resetId = (id) => {
    console.log('preview reset id', id);
    this.id = id;
  };

  @action onLoad = (e) => {
    console.log('...on load');
    const iframe = e.target;
    this.iframe = e.target;
    this.innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    this.listenToIfEventIsReady();
  };

  @action scrollToSection = () => {
    console.log('ready....!!', this.step, this.timerId);
    clearInterval(this.timerId);
    if (this.step === CREATE_EVENT_STEPS.Basic) {
      return;
    }

    const elementId = EVENT_ELEMENT_ID[this.step];
    const element = this.innerDoc.getElementById(elementId);
    if (element) {
      const { top } = element.getBoundingClientRect();
      if (top) {
        this.iframe.contentWindow.scrollTo(0, top - 100);
      }
    }
  };

  listenToIfEventIsReady = () => {
    const timer = setInterval(() => {
      console.log('interval', busyCursor.isEventDetailLoading, timer);
      if (!busyCursor.isEventDetailLoading) {
        runInAction(() => {
          this.isReady = true;
        });
        this.scrollToSection();
        clearInterval(timer);
      }
    }, 1000);
    runInAction(() => {
      console.log('set timer', timer);
      this.timerId = timer;
    });
  };
}

export default PreviewViewModel;
